// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

//Font awesome
@import "node_modules/font-awesome/scss/font-awesome";

.red{
  color: $brand-danger;
}

.green{
  color: $brand-success;
}